<template>
  <div class="mt-3">
    <div v-if="isShowGrid">
      <va-card title="Inventory List">
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class=" flex mt-1" v-if="ifCheckboxValue && !ifCheckboxCmdValue && (getRole() != 'OPERATOR' && getRole() != 'HEPI_OPERATOR')">
            <va-button
              color="#1193f4"
              type="submit"
              @click="isAssigned()">
              Assign Organization
            </va-button>
          </div>
          <div class="flex xs12 md2 offset--md" v-if="filterFlag && (getRole() != 'OPERATOR' && getRole() != 'HEPI_OPERATOR')">
            <va-select
              class="mt-2"
              placeholder="Select Operator"
              v-model="opfilter"
              textBy="org_name"
              searchable
              @input="getOrgFilter(opfilter)"
              :options="operFilterArr"
              :error="!!operFilterErrors.length"
              :error-messages="operFilterErrors"
            />
          </div>
          <div slot="actions" class="flex xs12 md2 offset--md">
            <va-button color="#A87D32" icon="entypo entypo-export" @click="exportFile()">Export</va-button>
          </div>
          <div class="flex" v-if="!ifCheckboxValue && ifCheckboxCmdValue">
            <va-button type="submit" style="margin-top: 11px;" @click="runCmd(null, 'Bulk')">Bulk Command</va-button>
            <va-button type="submit" style="margin-top: 11px;" @click="changeOp()">Change Organization</va-button>
          </div>
          <va-modal
            v-if="!ifCheckboxValue && ifCheckboxCmdValue"
            v-model="ifChange"
            class="flex xs12 md12"
            large
            okText="Update"
            cancelText="Cancel"
            @ok="bulkAssignEmm()"
            @cancel="cancel()"
            noOutsideDismiss
            noEscDismiss
          >
            <va-card title="Bulk Assign Operator">
              <div>
                <div class="va-form-label">Selected Inventories</div>
                <va-button class="button-dis" outline v-for="bulkContent in bulkContents">
                  <center>{{bulkContent.unique_id}}</center>
                </va-button>
              </div>
              <div class="flex md12">
                <span class="va-form-required-label">Role</span>
                <va-select
                    class="mt-2"
                    placeholder="Select Operator"
                    v-model="role"
                    textBy="type"
                    searchable
                    :options="roleArr"
                    @input="roleOperator(role)"
                    :error="!!RoleErrors.length"
                    :error-messages="RoleErrors"
                  />
                <div class="va-form-label va-form-required-label">Operator</div>
                <va-select
                  v-model="org_name"
                  textBy="org_name"
                  :options="orgData"
                  searchable
                  class="mt-1"
                  :error="!!org_nameErrors.length"
                  :error-messages="org_nameErrors"
                />
              </div>

            </va-card>
          </va-modal>
        </div>
        <va-button
          v-if="bulkCommandFlag && (emmListOption.length > 0 && (getRole() != 'OPERATOR' && getRole() != 'HEPI_OPERATOR'))"
          :color="selectOtns.color"
          class="select_all"
          small
          :icon="selectOtns.icon"
          @click="selectOtns.click">
          {{selectOtns.value}}
        </va-button>
        <va-button
          v-if="!bulkCommandFlag && (emmListOption.length > 0 && (getRole() != 'OPERATOR' && getRole() != 'HEPI_OPERATOR'))"
          :color="selectOtns.color"
          class="select_all"
          small
          :icon="selectOtns.icon"
          @click="selectOtns.click">
          {{selectOtns.value}}
        </va-button>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          clickable
        >
          <template slot="checkbox" v-if="props.rowData.checkboxFlag && (getRole() != 'OPERATOR' && getRole() != 'HEPI_OPERATOR')" slot-scope="props">
            <va-checkbox
              :value="props.rowData.checkbox_value"
              @input="select(props.rowData)"
            />
          </template>
          <template slot="checkbox_cmd" v-if="bulkCommandFlag" slot-scope="props">
            <va-checkbox
              :value="props.rowData.checkbox_cmd"
              @input="selectCmd(props.rowData)"
            />
          </template>
          <template slot="actions" slot-scope="props">
          <va-button v-if="(getRole() == 'ADMIN' || getRole() == 'PROVIDER' || getRole() == 'SUPPORT' || getRole() == 'OPERATOR' || getRole() == 'HEPI_OPERATOR'|| getRole() == 'RESELLER')" flat color="#FCA103" @click="runCmd(props.rowData, 'single')">Run</va-button>
            <va-button v-if="(getRole() == 'ADMIN' || getRole() == 'PROVIDER' || getRole() == 'SUPPORT' || getRole() == 'OPERATOR' || getRole() == 'HEPI_OPERATOR'|| getRole() == 'RESELLER')" flat color="#a632a8" @click="pendingCmd(props.rowData)">Pending</va-button>
            <va-button v-if="(getRole() == 'ADMIN' || getRole() == 'PROVIDER' || getRole() == 'SUPPORT')" flat color="#008080" @click="removeEmm(props.rowData)">Remove</va-button>
            <va-button flat small color="gray" style="width: 20px; height: 19px;" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button><br>
            <!-- <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button> -->
          </template>
        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Inventory"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteInventory(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <va-modal
      v-model="isAssign"
      class="flex xs12 md12"
      large
      okText="Confirm"
      cancelText="Cancel"
      @ok="bulkUpdateEmm()"
      @cancel.prevent="cancel()"
      noOutsideDismiss
      noEscDismiss
    >
      <va-card title="Assign Operator">
        <div>
          <div class="va-form-label">Selected Inventories</div>
          <va-button class="button-dis" outline v-for="bulkContent in bulkContents">
            <center>{{bulkContent.unique_id}}</center>
          </va-button>
        </div>
        <span class="va-form-required-label">Role</span>
        <va-select
          class="mt-2"
          placeholder="Select Operator"
          v-model="role"
          textBy="type"
          :options="roleArr"
          @input="roleOperator(role)"
          :error="!!RoleErrors.length"
          :error-messages="RoleErrors"
        />
        <span class="va-form-required-label">Organization</span>
        <div class="flex md12">
          <va-select
            v-model="org_name"
            textBy="org_name"
            :options="orgData"
            searchable
            class="mt-1"
            :error="!!org_nameErrors.length"
            :error-messages="org_nameErrors"
          />
        </div>
        <div class="mt-2">
          <va-checkbox
            v-if="(getRole() == 'ADMIN'|| getRole() == 'PROVIDER' || getRole() == 'SUPPORT')"
            v-model="ott_supported"
            label="OTT Supported"
          />
        </div>
      </va-card>
    </va-modal>
    <va-modal
      v-model="isRun"
      class="flex xs12 md12"
      large
      okText="Run"
      cancelText="Cancel"
      @ok="RunEmm()"
      @cancel="cancel()"
      noOutsideDismiss
      noEscDismiss
    >
      <va-card title="Run Command">
        <div class="flex md12">
          <span v-if="!bulkCommandFlag">Serial No</span>
          <va-input
            v-if="!bulkCommandFlag"
            v-model="unique_id"
            type="text"
            class="mt-1"
            :disabled="true"
          />
        </div>
        <div class="flex md12">
          <span class="va-form-required-label">Command</span>
          <va-select
            class="mt-2"
            placeholder="Select Command"
            v-model="cmd"
            textBy="reference_name"
            searchable
            :options="cmdArr"
            :error="!!CmdErrors.length"
            :error-messages="CmdErrors"
          />
        </div>
      </va-card>
    </va-modal>
    <va-modal
      v-model="pendingFlag"
      class="flex xs12 md12"
      large
      okText="Execute"
      cancelText="Cancel"
      @ok="RunAllCmd()"
      @cancel="cancel()"
      noOutsideDismiss
      noEscDismiss
    >
      <va-card title="Pending Commands">
        <div class="flex md12">
          <span>Serial No : {{unique_id}}</span>
        </div><br>
        <div class="flex md12">
          <div v-for="(row, i) in pendingCmdList" :key="i">
            <input
              class="width left"
              v-model="row.reference_name"
              type="text"
              disabled="true"
            />
            <va-button flat small color="red" icon="fa fa-remove" @click="delCmdList(row,i)" class="ma-0"></va-button>
          </div>
        </div>
      </va-card>
    </va-modal>
    <va-modal
      v-model="isShowForm"
      class="flex xs12 md12"
      large
      hide-default-actions
      noOutsideDismiss
      noEscDismiss
    >
      <span class="title">update inventory</span>
      <form class="mt-3">
        <div class="mt-2">
          <div v-if="(getRole() == 'ADMIN' || getRole() == 'PROVIDER' || getRole() == 'SUPPORT') && roleFlag">
            <span class="va-form-required-label">Role</span>
            <va-select
              class="mt-2"
              placeholder="Select Operator"
              v-model="role"
              textBy="type"
              searchable
              :options="roleArr"
              @input="roleOperator(role)"
              :error="!!RoleErrors.length"
              :error-messages="RoleErrors"
            />
          </div>
          <div v-if="(getRole() == 'RESELLER') || (getRole() == 'HEPI_RESELLER')">
            <span class="va-form-required-label">Role</span>
            <va-input
              v-model="role"
              type="text"
              class="mt-1"
              :disabled="true"
            />
          </div>
          <div class="mt-2" v-if="(getRole() == 'ADMIN' || getRole() == 'PROVIDER' || getRole() == 'SUPPORT') && resellerFlag">
            <span>Reseller Organization</span>
            <va-input
              v-model="resellers_org_name"
              type="text"
              class="mt-1"
              :disabled="true"
            />
          </div>
          <span class="va-form-required-label">Organization</span>
          <multiselect
            placeholder="Select Organization"
            v-model="org_name"
            :close-on-select="true"
            :searchable="true"
            :clear-on-select="false"
            :allow-empty="false"
            track-by="id"
            id="example"
            :multiple="false"
            label="org_name"
            :options="orgData"
          />
        </div>
        <div class="mt-2">
          <span>Serial No</span>
          <va-input
            v-model="unique_id"
            type="text"
            class="mt-1"
            :disabled="true"
          />
        </div>
        <div class="mt-2">
          <span>Make</span>
          <va-input
            v-model="make"
            type="text"
            class="mt-1"
            :disabled="true"
          />
        </div>
        <div class="mt-2">
          <span>Model</span>
          <va-input
            v-model="model"
            type="text"
            class="mt-1"
            :disabled="true"
          />
        </div>
        <div class="mt-2">
          <span>Status</span>
          <va-input
            v-model="status"
            type="text"
            class="mt-1"
            :disabled="true"
          />
        </div>
        <div class="mt-2">
          <va-checkbox
            v-model="ott_supported"
            label="OTT Supported"
          />
        </div>
        <va-separator
          class="mt-5"
        />
        <va-button
          class="va-button-normal-cancel flex xs2 offset--md2 offset--lg8"
          type="submit"
          @click.prevent="list()">
          Cancel
        </va-button>
        <va-button
          color="#1193f4"
          type="submit"
          @click.prevent="updateEMM()">
          Update
        </va-button>
      </form>
    </va-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import { exportToFile } from '../../i18n/exportFile.js'
import config from '../../i18n/en.json'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)

export default {
  name: 'Inventory',
  created () {
    this.getAllEMM()
  }, 
  data () {
    return {
      isShowGrid: true,
      selectOtns: {
        icon: 'entypo entypo-check',
        click: this.selectAll,
        value: 'Select All',
        color: 'primary',
      },
      isShowForm: false,
      term: null,
      bulkCommandFlag: false,
      org_name: '',
      all_org_data:'',
      org_id: '',
      org_nameErrors: [],
      orgData: [],
      unique_id: '',
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      emmListOption: [],
      make: '',
      model: '',
      status: '',
      emm_payload: {},
      opfilter: '',
      operFilterArr: [],
      operFilterErrors: [],
      checkboxFlag: false,
      checkbox_value: false,
      filterFlag: true,
      ifCheckboxValue: false,
      ott_supported: false,
      ifCheckboxCmdValue: false,
      isRun: false,
      cmd: '',
      cmdArr: [],
      CmdErrors: [],
      bulkEmm: [],
      pendingFlag: false,
      pendingCmdList: [],
      bulkOrgFlag: false,
      bulkContents: [],
      ifChange: false,
      showRemoveModal: false,
      isAssign: false,
      msg: '',
      role: '',
      roleArr: [],
      RoleErrors: [],
      roleFlag: false,
      resellers_org_name: '',
      resellerFlag: false,
      operatorAssignFlag: false,
      location_flag: false
    }
  },
  computed: {
    formReady () {
      return !this.org_nameErrors.length &&
             !this.CmdErrors.length
    },
    fields () {
      var role = Vue.$cookies.get('userRole')
      if (role == 'ADMIN' || role == 'PROVIDER' || role =='SUPPORT') {
        return [
          {
            name: '__slot:checkbox',
          },
          {
            name: '__slot:checkbox_cmd',
          },
          {
            name: 'org_name',
            title: 'Organization Name',
          },
          {
            name: 'resellers_org_name',
            title: 'Resller Organization Name',
          },
          {
            name: 'unique_id',
            title: 'Serial No',
          },
          {
            name: 'mac_address',
            title: 'Mac Address',
          },
          {
            name: 'version',
            title: 'Version',
          },
          {
            name: 'make',
            title: 'Make',
          },
          {
            name: 'model',
            title: 'Model',
          },
          {
            name: 'status',
            title: 'Status',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      } else {
        return [
          {
            name: '__slot:checkbox',
          },
          {
            name: 'org_name',
            title: 'Organization Name',
          },
          {
            name: 'unique_id',
            title: 'Serial No',
          },
          {
            name: 'mac_address',
            title: 'Mac Address',
          },
          {
            name: 'version',
            title: 'Version',
          },
          {
            name: 'make',
            title: 'Make',
          },
          {
            name: 'model',
            title: 'Model',
          },
          {
            name: 'status',
            title: 'Status',
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right',
          },
        ]
      }
    },
    filteredData () {
      return search(this.term, this.emmListOption)
    },
  },
  methods: {
    getRole () {
      return Vue.$cookies.get('userRole')
    },
    selectAll () {
      var role = Vue.$cookies.get('userRole')
      if (this.bulkCommandFlag == true) {
        this.emmListOption.map(cust => cust.checkbox_cmd = true)
        this.selectOtns = {
          icon: 'entypo entypo-cancel',
          click: this.deSelectAll,
          value: 'De Select All',
          color: 'danger',
        }
        this.ifCheckboxCmdValue = true
      } else {
        this.emmListOption.map(function (cust) {
          if ((cust.org_id == null && cust.resellers_org_id == null && (role == 'ADMIN' || role == 'PROVIDER')) || (cust.org_id == null && (role == 'RESELLER' || role == 'HEPI_RESELLER'))) {
            cust.checkbox_value = true
          }
        })
        this.selectOtns = {
          icon: 'entypo entypo-cancel',
          click: this.deSelectAll,
          value: 'De Select All',
          color: 'danger',
        }
        this.ifCheckboxValue = true
        this.filterFlag = false
      }
    },
    deSelectAll () {
      this.emmListOption.map(function (cust) {
        cust.checkbox_cmd = false
        cust.checkbox_value = false
      })
      this.selectOtns = {
        icon: 'entypo entypo-check',
        click: this.selectAll,
        value: 'Select All',
        color: 'primary',
      }
      this.ifCheckboxCmdValue = false
      this.ifCheckboxValue = false
      this.filterFlag = true
      this.opfilter = ''
      this.getAllEMM()
    },
    delCmdList (data) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.delete(config.menu.host + '/emm/pending_command/' + data.cmd_id).then(response => {
        loader.hide()
        this.pendingCmdList = this.pendingCmdList.filter(function (iter) {
          return iter.cmd_id != data.cmd_id
        })
      }, function (err) {
        loader.hide()
      })
    },
    pendingCmd (data) {
      this.$http.get(config.menu.host + '/emm/pending_commands/' + data.unique_id).then(response => {
        if (response.body.length == 0) {
          Vue.notify({ text: 'No pending commands for this stb', type: 'error' })
        } else {
          this.unique_id = data.unique_id
          this.pendingFlag = true
          this.pendingCmdList = response.body
        }
      }, function (err) {
        Vue.notify({ text: error.body, type: 'error' })
      })
    },
    roleOperator (role) {
      this.$http.get(config.menu.host + '/org')
        .then(res => {
          this.all_org_data=res.body;
          this.orgData = res.body.filter(function (value, count) {
            value.id = count
            if (value.org_type == role) {
              return !res.body.some(function (data) {
                return value.org_name === data.name
              })
            }
          })
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    role_define () {
      var role = Vue.$cookies.get('userRole')
      if (role == 'ADMIN' || role == 'PROVIDER' || role == 'SUPPORT') {
        this.roleArr = ['RESELLER', 'OPERATOR', 'HEPI_RESELLER', 'HEPI_OPERATOR']
      }else if(role == 'HEPI_RESELLER'){
        this.roleArr = ['HEPI_OPERATOR']
      }else {
        this.roleArr = ['OPERATOR']
      }
    },
    RunAllCmd () {
      var payload = {
        serial_no: this.unique_id,
        commands: this.pendingCmdList,
      }
      this.$http.post(config.menu.host + '/emm/run_pending_commands', payload).then(response => {
        Vue.notify({ text: response.body, type: 'success' })
      }, function (err) {
        Vue.notify({ text: err.body, type: 'error' })
      })
    },
    getAllEMM (page = 0) {
      if (this.opfilter) {
        return this.getOrgFilter(this.opfilter)
      }
      var filt = this.filter ? this.filter.id : 'all'
      var role = Vue.$cookies.get('userRole'); var reseller_role_id = Vue.$cookies.get('reseller_id')
      var orgId = Vue.$cookies.get('org-id')
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/emm')
        .then(response => {
          loader.hide()
          var count = 0
          let index = 0
          if (role == 'ADMIN' || role=='SUPPORT' || role == 'PROVIDER') {
            this.filterFlag = true
            this.getOrg()
            response.body.map(function (emmItem, i) {
              emmItem.checkboxFlag = !!((!emmItem.org_id && !emmItem.resellers_org_id))
              count++
              emmItem.checkbox_value = false
              emmItem.rowPosition = index++
            })
            this.emmListOption = response.body
            this.selectOtns = {
              icon: 'entypo entypo-check',
              click: this.selectAll,
              value: 'Select All',
              color: 'primary',
            }
            this.ifCheckboxCmdValue = false
            this.ifCheckboxValue = false
            this.opfilter = ''
          } else {
            this.getOrg()
            var emmList = []
            response.body.filter(function (emm_value) {
              if ((role == 'RESELLER' || role == 'HEPI_RESELLER')) {
                if (emm_value.resellers_org_id == reseller_role_id) {
                  emmList.push(emm_value)
                }
              }
              if (role == 'OPERATOR' || role == 'HEPI_OPERATOR') {
                if (emm_value.org_id == orgId) {
                  emmList.push(emm_value)
                }
              }
            })
            emmList.map(function (emmItem, i) {
              emmItem.checkboxFlag = (!emmItem.org_id)
              count++
              emmItem.checkbox_value = false
              emmItem.rowPosition = index++
            })
            this.emmListOption = emmList
          }
          this.$http.get(config.menu.host + '/emm/commands').then(respo => {
            this.cmdArr = respo.body
            this.$http.get(config.menu.host + '/org').then(res => {
              loader.hide()
              this.orgData = res.body.filter(function (value, count) {
                value.id = count
                if (value.org_type == 'OPERATOR' || value.org_type == 'HEPI_OPERATOR') {
                  return !res.body.some(function (data) {
                    return value.org_name === data.name
                  })
                }
              })
            })
          })
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    getOrg () {
      var role = Vue.$cookies.get('userRole')
      this.$http.get(config.menu.host + '/org')
        .then(respo => {
          this.operFilterArr = respo.body.filter(function (thing) {
            if (role == 'ADMIN'|| role == 'PROVIDER') {
              return (thing.org_type == 'OPERATOR' || thing.org_type == 'HEPI_OPERATOR'|| thing.org_type == 'HEPI_RESELLER'|| thing.org_type == 'RESELLER')
            }else if(role == 'HEPI_RESELLER'){
              return thing.org_type == 'HEPI_OPERATOR' 
            }else {
              return thing.org_type == 'OPERATOR'
            }
          })
          this.operFilterArr.map(function (arg, i) { arg.id = i + 1 })
        })
    },
    select (data) {
      this.filterFlag = false
      this.ifCheckboxValue = true
      this.emmListOption[data.rowPosition].checkbox_value = !this.emmListOption[data.rowPosition].checkbox_value
      const emm_array_selected = this.emmListOption.filter(data => data.checkbox_value)
      if (emm_array_selected.length == 0) {
        this.opfilter = ''
        this.filterFlag = true
        this.ifCheckboxValue = false
        this.selectOtns = {
          icon: 'entypo entypo-check',
          click: this.selectAll,
          value: 'Select All',
          color: 'primary',
        }
        this.getAllEMM()
      } else {
        this.selectOtns = {
          icon: 'entypo entypo-cancel',
          click: this.deSelectAll,
          value: 'De Select All',
          color: 'danger',
        }
      }
    },
    selectCmd (data) {
      this.filterFlag = false
      this.ifCheckboxCmdValue = true
      this.emmListOption[data.rowPosition].checkbox_cmd = !this.emmListOption[data.rowPosition].checkbox_cmd
      const emm_array_selected = this.emmListOption.filter(data => data.checkbox_cmd)
      this.filterFlag = true
      if (emm_array_selected.length == 0) {
        this.filterFlag = true
        this.ifCheckboxCmdValue = false
        this.selectOtns = {
          icon: 'entypo entypo-check',
          click: this.selectAll,
          value: 'Select All',
          color: 'primary',
        }
        this.getAllEMM()
      } else {
        this.selectOtns = {
          icon: 'entypo entypo-cancel',
          click: this.deSelectAll,
          value: 'De Select All',
          color: 'danger',
        }
      }
    },
    updateEMM () {
      var role = Vue.$cookies.get('userRole'), vm = this;
      this.RoleErrors = this.role ? [] : ['']
      this.org_nameErrors = this.org_name ? [] : ['']

      if (!this.formReady) {
        return
      }

      if (role == 'ADMIN' || role == 'PROVIDER') {
        if (this.role == 'OPERATOR' || this.role == 'HEPI_OPERATOR' || this.operatorAssignFlag) {
          var reseller_role = (this.role == 'OPERATOR') ? 'RESELLER' : 'HEPI_RESELLER'
	  var filter = []
if(this.all_org_data.length > 0){
          var filter = this.all_org_data.filter(function(alldata){
            if((alldata.reseller_org_id == vm.org_name.reseller_org_id) && (alldata.org_type == reseller_role)){
              return alldata;
            }
          })
}
          var payload = {
            id: this.id,
            org_name: (typeof(this.org_name) != 'string') ? this.org_name.org_name : this.org_name,
            org_id: (typeof(this.org_name) != 'string') ? this.org_name.org_id : this.org_id,
            roles: this.role,
            serial_no: this.unique_id,
            ott_supported: this.ott_supported
	  }
	 if(filter.length > 0){
           payload['resellers_org_id']= filter[0].reseller_org_id,
            payload['resellers_org_name']=filter[0].org_name
	}
          //}
        } else {
          var payload = {
            id: this.id,
            resellers_org_id: this.org_name.reseller_org_id,
            resellers_org_name: this.org_name.org_name,
            roles: this.role,
            serial_no: this.unique_id,
            ott_supported: this.ott_supported,
          }
        }
      } else {
        var payload = {
          id: this.id,
          org_name: (typeof(this.org_name) != 'string') ? this.org_name.org_name : this.org_name,
          org_id: (typeof(this.org_name) != 'string') ? this.org_name.org_id : this.org_id,
          serial_no: this.unique_id,
          ott_supported: this.ott_supported,
        }
      }
      if(this.location_flag && typeof(this.org_name) != 'string') payload['location'] = this.org_name.state;

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/emm', payload)
        .then(respon => {
          loader.hide()
          if (respon && respon.body) {
            Vue.notify({ text: respon.body, type: 'success' })
          }
          this.list()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        },
        )
    },
    changeOp () {
      this.org_name = ''
      this.org_nameErrors = []
      this.orgData = []
      this.ifChange = true
      this.role = ''
      if(Vue.$cookies.get('userRole') == 'ADMIN'|| Vue.$cookies.get('userRole') == 'PROVIDER' || Vue.$cookies.get('userRole') == 'SUPPORT') {
        this.roleArr = ['RESELLER', 'HEPI_RESELLER', 'OPERATOR', 'HEPI_OPERATOR'];
      }else if(Vue.$cookies.get('userRole') == 'RESELLER') {
        this.roleArr = ['OPERATOR'];
      }else if(Vue.$cookies.get('userRole') == 'HEPI_RESELLER') {
        this.roleArr = ['HEPI_OPERATOR'];
      };
      this.bulkCommandFlag = true
      var bulkUpdateArr = []

      this.emmListOption.map(function (emmItem) {
        if (emmItem.checkbox_cmd) {
          bulkUpdateArr.push(emmItem)
        };
      });
      this.bulkContents = bulkUpdateArr
    },
    isAssigned () {
      this.role = ''
      this.RoleErrors = []
      this.org_name = ''
      this.org_nameErrors = []
      this.ott_supported = false
      this.isAssign = true
      this.role_define()
      var bulkUpdateArr = []
      this.emmListOption.map(function (emmItem) {
        if (emmItem.checkbox_value) {
          bulkUpdateArr.push(emmItem)
        }
      })
      this.bulkContents = bulkUpdateArr
    },
    bulkUpdateEmm () {
      var role = Vue.$cookies.get('userRole')
      var bulkUpdateArr = []
      this.RoleErrors = this.role ? [] : ['']
      this.org_nameErrors = this.org_name ? [] : ['']

      if (!this.formReady) { return }

      this.emmListOption.map(function (emmItem) {
        if (emmItem.checkbox_value) {
          bulkUpdateArr.push(emmItem)
        }
      })
    
      if (role == 'ADMIN'|| role == 'PROVIDER'  || role == 'SUPPORT') {
        if (this.role == 'OPERATOR' || this.role == 'HEPI_OPERATOR') {
          var vm=this;
          var reseller_role = (this.role == 'OPERATOR') ? 'RESELLER' : 'HEPI_RESELLER'
          var filter = this.all_org_data.filter(function(alldata){
            if((alldata.reseller_org_id == vm.org_name.reseller_org_id) && (alldata.org_type==reseller_role)){
              return alldata;
            }
          })
          var payload = {
            id: this.id,
            org_id: this.org_name.org_id,
            org_name: this.org_name.org_name,
            resellers_org_id: filter[0].reseller_org_id,
            resellers_org_name:filter[0].org_name,
            arr: bulkUpdateArr,
            roles: this.role,
            location: this.org_name.state,
            ott_supported: this.ott_supported,
          }
        } else {
          var payload = {
            id: this.id, 
            arr: bulkUpdateArr,
            resellers_org_id: this.org_name.reseller_org_id,
            resellers_org_name: this.org_name.org_name, 
            roles: this.role,
            location: this.org_name.state,
            ott_supported: this.ott_supported,
          }
        }
      } else {
        var payload = {
          id: this.id,
          org_id: this.org_name.org_id,
          org_name: this.org_name.org_name,
          arr: bulkUpdateArr,
          roles: this.role
        }
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/emm/update', payload)
        .then(respo => {
          loader.hide()
          if (respo && respo.body) {
            Vue.notify({ text: respo.body, type: 'success' })
          }
          this.opfilter = ''
          this.list()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    bulkAssignEmm () {
      this.org_nameErrors = this.org_name ? [] : ['']
      if (!this.formReady) { return this.ifChange = true };

      var bulkUpdateArr = []

      this.emmListOption.map(function (emmItem) {
        if (emmItem.checkbox_cmd) {
          bulkUpdateArr.push(emmItem)
        }
      })

      var vm = this;
      var reseller_role = (this.role == 'OPERATOR') ? 'RESELLER' : 'HEPI_RESELLER'
      var filter = this.all_org_data.filter(function(alldata){
        if((alldata.reseller_org_id == vm.org_name.reseller_org_id) && (alldata.org_type == reseller_role)){
          return alldata;
        }
      })

      if(this.role == 'RESELLER' || this.role == 'HEPI_RESELLER'){
        var payload = {
          resellers_org_id: this.org_name.reseller_org_id,
          resellers_org_name: this.org_name.org_name,
          org_name:  null,
          org_id: this.org_name.org_id,
          location: this.org_name.state,
          serial_no: bulkUpdateArr
        }
      }else{
        var payload = {
          org_name: this.org_name.org_name,
          org_id: this.org_name.org_id,
          ott_supported: this.ott_supported,
          location : this.org_name.state, 
          serial_no : bulkUpdateArr,
          resellers_org_id: filter[0].reseller_org_id,
          resellers_org_name: filter[0].org_name
        }
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/emm/changeOperator', payload)
        .then(respo => {
          loader.hide()
          if (respo && respo.body) {
            Vue.notify({ text: respo.body, type: 'success' })
          }
          this.opfilter = ''
          this.list()
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    getOrgFilter (data) {

      if (data) {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/emm/' + data.org_id).then(resp => {
          loader.hide()
          this.emmListOption = resp.body
          var index = 0
          this.emmListOption.map(function (data) {
            data.checkbox_cmd = false
            data.rowPosition = index++
          })
          this.bulkCommandFlag = true
        })
      } else if (data == '') {
        this.bulkCommandFlag = false
        this.ifCheckboxCmdValue = false
        this.selectOtns = {
          icon: 'entypo entypo-check',
          click: this.selectAll,
          value: 'Select All',
          color: 'primary',
        }
        this.getAllEMM()
      } else {
        this.getAllEMM()
      }
    },
    exportFile () {
      var rowKeys = {
        org_name: 'Organization Name',
        resellers_org_name : 'Reseller Organization Name',
        mac_address: 'Mac Address',
        unique_id: 'Serial No',
        model: 'Model',
        version: 'version',
        status: 'status',
      }
      exportToFile(this.emmListOption, rowKeys, 'Inventory List', 'CSV')
    },
    runCmd (data, flag) {
      if (flag == 'single') {
        this.unique_id = data.unique_id
      } else {
        this.bulkCommandFlag = true
      }
      this.isRun = true
      this.cmd = ''
      this.CmdErrors = []
    },
    RunEmm () {
      this.CmdErrors = this.cmd ? [] : ['']
      if (!this.formReady) {
        return this.isRun = true
      }
      var bulkUpdateArr = []
      if (this.bulkCommandFlag) {
        this.emmListOption.map(function (emmItem) {
          if (emmItem.checkbox_cmd) {
            bulkUpdateArr.push(emmItem)
          }
        })
      }

      var role = Vue.$cookies.get('userRole')
      var role_name = Vue.$cookies.get('first_name')
      var payload = {
        reference_name: this.cmd.reference_name,
        command: this.cmd.command,
        username: role_name,
        role: role,
        type: this.cmd.type,
      }
      if (this.bulkCommandFlag) {
        payload.serial_no = bulkUpdateArr
        payload.category = 'bulk'
      } else {
        payload.serial_no = this.unique_id
        payload.category = 'single'
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/emm/cmd', payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    edit (row) {
      var role = Vue.$cookies.get('userRole')
      this.isShowForm = true
      this.role_define()
      var org = row.org_name ? row.org_name : ''
      this.org_name = { org_name: org, org_id: row.org_id }
      this.org_id = row.org_id ? row.org_id : ''
      this.id = row.id
      this.unique_id = row.unique_id
      this.make = row.make
      this.model = row.model
      this.status = row.status
      this.emm_payload = row
      this.ott_supported = row.ott_supported ? row.ott_supported : false
      this.resellers_org_name = row.resellers_org_name
      this.location_flag = (row.location == null) ? true : false
      this.operatorAssignFlag = (row.resellers_org_name) ? true : false
      if (role == 'ADMIN'|| role == 'PROVIDER') {
        this.role = row.roles
        if (row.resellers_org_name) {
          this.$http.get(config.menu.host + '/org').then(res => {
            this.orgData = res.body.filter(function (value, count) {
              value.id = count
              if ((value.reseller_org_id == row.resellers_org_id) && (value.org_type == 'OPERATOR' || value.org_type == 'HEPI_OPERATOR')) {
                return !res.body.some(function (data) {
                  return value.org_name === data.name
                })
              }
            })
          })
        }
      } else if (role == 'RESELLER') {
        this.roleOperator('OPERATOR')
        this.role = 'OPERATOR'
      } else if (role == 'HEPI_RESELLER') {
        this.roleOperator('HEPI_OPERATOR')
        this.role = 'HEPI_OPERATOR'
      }
      this.roleFlag = !!((row.org_name == null && row.resellers_org_name == null))
      this.resellerFlag = !!(row.resellers_org_name)
    },
    list () {
      if (this.checkbox_value == false) {
        this.filterFlag = true
        this.ifCheckboxValue = false
      }
      this.bulkCommandFlag = false
      this.isShowForm = false
      this.isShowGrid = true
      this.getAllEMM()
    },
    removeEmm (data) {
      this.msg = 'Are you sure to remove the inventory ' + data.unique_id + ' ?'
      this.entity = data
      this.showRemoveModal = true
    },
    cancel () {
      this.showRemoveModal = false
    },
    deleteInventory (data) {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/emm/' + data.id + '/' + data.box_no).then(respon => {
        loader.hide()
        if (respon && respon.body) {
          Vue.notify({ text: respon.body, type: 'success' })
        }
        this.list()
      }, error => {
        loader.hide()
        if (error && error.body) {
          Vue.notify({ text: error.body, type: 'error' })
        }
      })
    },
    cancel () {
      this.isRun = false
      this.bulkCommandFlag = false
      this.getAllEMM()
    },
    search: function (term) {
      this.term = term
    },
  },
}
</script>

<style>
.labelwidth {
  width: 149px;
  margin-top: 17px;
}

.left {
  margin-left: 2px;
  color: black;
  margin-right: 10px;
}

.width {
  width: 150px;
}
</style>
